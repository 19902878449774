body {
    .custom-time-picker {
        &__time {
            &__input {
                width: rem(44);
                height: rem(44);
                &.invalid {
                    border-color: var(--danger) !important;
                }
            }
            &__divider {
                display: block;
                margin: 0 rem(4);
            }
        }
        &__dayPeriod {
            margin-left: rem(15);
            .custom-control-radio-label {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}