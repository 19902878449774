.dropdown {
    &.show {
        button.dropdown-toggle {
            color: #000;
            border-color: #BCB8B2;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    &-filter {
        button.dropdown-toggle {
            font-size: rem(15);
            font-weight: 700;
            border: 1px solid #BCB8B2;
            border-radius: 6px;
            background-color: white !important;
            color: #000;
            padding: rem(10) rem(10);
            min-width: rem(115);
            width: 100%;
            &:focus {
                outline: none;
                box-shadow: none;
            }
           
            svg {
                margin-left: rem(13);
            }
            &:after {
                display: none;
            }
        }
        .dropdown-menu {
            box-shadow:0px 0px 0px 2px var(--white) inset;
            border-radius: 0;
            border: 0;
            filter: drop-shadow(0px 1px 8px #283044);
            background-color: var(--aqua-haze);
            a {
                color: var(--dark);
                transition: 0.25s ease-in-out;
                &:hover {
                    font-weight: 700;
                    background-color: #EEEEE5;
                }
            }
        }
    }
}