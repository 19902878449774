.signin {
    * {
        font-family: $font-family-base;
    }
    min-height: 100vh;

    @media screen and (min-width: 992px) {
        height: 100vh;
    }

    &__start-text {
        h1 {

            &:first-child {
                font-weight: 200;
            }

            &:not(:first-child) {
                font-weight: 400;
            }
        }

        @media screen and (min-width: 768px) {
            h1 {
                font-size: rem(32);
            }
        }

        @media screen and (min-width: 1200px) {
            h1 {
                font-size: rem(50);
            }
        }
    }

    .copyright {
      font-size: rem(14);

      a {
        font-weight: 700;
        transition: color 0.25s ease-in-out;

          &:hover {
              color: #00ACC8;
          }
      }
    }

    .form-container {
        overflow: auto;
        background: var(--aqua-haze);
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.12);
        padding: rem(0) 7%;
       

        @media screen and (min-width: 768px) {
            padding: rem(0) 20%;
        }

        @media screen and (min-width: 992px) {
            &__row {
                padding-top: 10% !important;
            }
        }
        small {
            font-size: rem(14);
            line-height: 14px;
            display: block;
            color: var(--dark);
        }

        a {
            font-weight: 700;
        }

        &__logo {
            @media screen and (max-width: 991px) {
                img {
                    max-width: 50%;
                }
            }
        }

        &__forgot {
            font-size: rem(14);

            a {
                transition: color 0.25s ease-in-out;

                &:hover {
                    color: #00ACC8;
                }
            }
        }

        &__redirect {
            span,
            a {
                font-size: rem(14);
                color: var(--primary);
            }

            a {
                transition: color 0.25s ease-in-out;

                &:hover {
                    color:var(--aqua-blue);
                }
            }
        }

        form {
            &.formInvalid {
                .form-group {
                    &.invalid {
                        label {
                            color: #d32f2f;
                        }

                        input {
                            border: 1.5px solid #d32f2f !important
                        }
                    }
                }
            }

            .form-group {
                label {
                    // font-size: rem(16);
                    transition: all 0.25s ease-in-out;
                }

                button {
                    font-size: rem(14);
                }
            }
        }
    }

    &__left {
        margin-top: rem(-76);
    }
}