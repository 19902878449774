.administration {
    &__item {
        max-width: rem(162);
        width: 100%;
        &__circle {
            border: 2px solid var(--aqua-blue);
            border-radius: 50%;
            width: rem(162);
            height: rem(162);
        }
    }
}