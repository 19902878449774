
.fs-14 {
  font-size: rem(14);
}
.fs-15 {
  font-size: rem(15) !important;
}
.fs-19 {
  font-size: rem(19);
}
.fs-13 {
  font-size: rem(13);
}

.fw-7 {
  font-weight: 700;
}

.line-height-normal {
  line-height: normal !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.font-arial-narrow {
  font-family: 'Arial Narrow',Aria,sans-serif;
}