.circle-checkbox {
	&__checkbox {
		display: none;
		&:checked ~ .circle-checkbox__check::after {
			content: '';
			position: absolute;
			width: rem(25);
			height: rem(25);
			top: 50%;
			left: 50%;
			color: #fff;
			background-image: url('../../assets/icons/check.svg');
			background-size: 75% 75%;
			background-repeat: no-repeat;
			background-position: center;
			transform: translate(-50%, -50%);
		}
	}
	&__check {
		display: flex;
		justify-self: center;
		align-items: center;
		width: rem(25);
		height: rem(25);
		border-radius: rem(6);
		border: rem(1) solid var(--dark-lighter--2);
		background-color: var(--white);
		position: relative;
		cursor: pointer;
		overflow: hidden;

		&:focus {
			box-shadow: 0 0 0 rem(3) var(--aqua-blue-shadow);
		}
		&--disabled {
			opacity: 0.3;
			&:hover {
				box-shadow: none;
				cursor: default;
			}
		}
	}
}
.custom-switch {
	&__checked-mode { 
		padding: rem(2) 0 rem(2) rem(4); 
		color: var(--white); 
	}
	&__unchecked-mode {
		text-align: right; 
		padding: rem(2) rem(4) rem(2) 0;  
		color: var(--white); 
	}
}