.guard-details {
    .upload-image {
        &__blank {
            &:hover {
                cursor: default;
            }
        }
        img {
            &:hover {
                cursor: default;
            }
        }
    }
}

.upload-image {
    $image-mw: 128;
    $image-h: 128;

    //max-width: rem($image-mw);

    width: 100%;
    &__blank {
        max-width: rem($image-mw);
        height: rem($image-h);
        width: 100%;
        border-radius: 50%;
        background-image: url('../../assets/images/default_profile.jpg');
        background-size: cover;
        background-position: center;
        object-fit: contain;
        &:hover {
            cursor: pointer;
        }
    }
    img {
        position: relative;
        max-width: rem($image-mw);
        height: rem($image-h);
        object-fit: cover;
        width: 100%;
        border-radius: 50%;
        &:hover {
            cursor: pointer;
        }
    }
    label.upload-image__label {
        font-size: rem(14);
        color: var(--dark-lighter);
        font-weight: 300;
        transition: 0.25s ease-in-out;
        &:hover {
            cursor: pointer;
            color: var(--aqua-blue);
        }
    }
    input[type="file"] {
        position: absolute;
        visibility: hidden;
        z-index: -10;
        opacity: 0;
    }

}

.img-clear-btn {
    position: absolute;
    top: rem(-20);
    right: rem(-10);
    z-index: 2;

    svg {
        &:hover {
            fill: #5c5c5c !important;
        }
    }
}


.btn {
    &:focus {
        box-shadow: none !important;
    }
}