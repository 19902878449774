
.w-md-25 {
    @include media(md) {
        width: 25% !important;
    }
}

.w-md-50 {
    @include media(md) {
        width: 50% !important;
    }
}

