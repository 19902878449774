// Utils
@import './scss/utils/variables.scss';
@import './scss/utils/functions.scss';
@import './scss/utils/mixins.scss';
@import './scss/utils/size.scss';

@import './scss/base/fonts.scss';

// SCSS Components
@import './scss/components/datepickers.scss';
@import './scss/components/scrollbar.scss';
@import './scss/components/loader.scss';
@import './scss/components/forms.scss';
@import './scss/components/tooltip.scss';
@import './scss/components/tabs.scss';
@import './scss/components/maps.scss';
@import './scss/components/shiftStatus.scss';
@import './scss/components/dropdowns.scss';


// React Components
@import './components/jobs/form-navigation/job-form-navigation.scss';
@import './components/upload/image-upload.scss';
@import './components/notification/notification.scss';
@import './components/holidays/holidays.scss';
@import './components/base-start-screen/signin.scss';
@import './components/jobs/tabs/job-tabs.scss';
@import './components/schedule/schedule-form.scss';
@import './components/layout/layout.scss';
@import './components/navigation/navigation.scss';
@import './components/cards/cards.scss';
@import './components/modal/modal.scss';
@import './components/titles/titles.scss';
@import './components/filters/dropdown-filter.scss';
@import './components/custom-select/custom-prediction.scss';
@import './components/custom-select/modal-multi-select.scss';
@import './components/tables/table.scss';
@import './components/breadcrumbs/breadcrumbs.scss';
@import './components/footer/footer.scss';
@import './components/custom-tooltip/custom-tooltip.scss';
@import './components/reports/reportActions.scss';
@import './components/wysiwyg/wysiwyg.scss';
@import './components/custom-time-picker/custom-time-picker.scss';
@import './pages/reports/reports.scss';
@import './components/modal/screen-notifications/screen-notifications.scss';
@import './components/checkbox/CustomCheckbox.scss';

// React Pages
@import './scss/pages/details.scss';
@import './scss/pages/administration.scss';
@import './scss/pages/settings.scss';


@import "~bootstrap/scss/bootstrap";
