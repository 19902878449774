.custom-prediction {
    // box-shadow:0px 0px 0px 2px var(--white) inset;
    border-radius: 0;
    border: 2px solid #FFFFFF;
    filter: drop-shadow(0px 1px 8px var(--dark-lighter--2));
    position: absolute;
    width: 100%;
    background-color: var(--aqua-haze);
    background: #F7F9FA;
    margin-top: 1.5px;
    &__item {
        font-family: $font-family-base;
        font-weight: 400;
        font-size: rem(15);
        line-height: 17px;
        color: var(--dark) !important;
        white-space: nowrap;
        transition: 0.25s ease-in-out;
        padding: rem(10) rem(10) rem(10) rem(20);
        &:hover {
            cursor: pointer;
            font-weight: 700;
            background-color: #EEEEE5;
        }
    }
}