.details-tabs {
    .nav {
        &.nav-tabs {
            background-color: var(--alto);
            justify-content: stretch;
            border-bottom: 6px solid var(--aqua-blue);
            a.nav-item {
                font-size: rem(14);
                font-weight: 400;
                color: var(--dark);
                border: 0;
                border-radius: 0;
                padding: rem(18) 0;
                flex: 1;
                &.active {
                    background-color: var(--aqua-blue);
                    font-weight: 700;
                }
                // flex-basis: 0;
                // flex-grow: 1;
                // max-width: 100%;
                text-align: center;
            }
        }
    }
    .tab-pane {
        padding: rem(20);
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
        table {
            button {
                width: rem(40);
                height: rem(40);
            }
        }
    }
    &--manage-users {
        .nav.nav-tabs {
            a.nav-item {
                // width: 33.3333%;
                text-align: center;
            }
        }
        .dropdown-menu {
            min-width: rem(100);
        }
    }
}
.details-tabs--manage-users {
    .nav {
        &.nav-tabs {
            a.nav-item {
                min-height: 57px;
                padding: 8px 5px;
                @include media(lg) {
                    padding: rem(18) 0;
                }
            }
        }
    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.details-tabs--jobs-details {
    .nav {
        &.nav-tabs {
            a.nav-item {
                padding: 5px 0;
                // min-width: 130px;
                @include media(sm) {
                    padding: 1.125rem 0;
                    // min-width: auto;
                }
            }
        }
    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.details-tabs--manage-dropdown {
    .nav {
        &.nav-pills {
            background-color: #ffffff;
            a.nav-link {
                font-size: rem(14);
                font-weight: 400;
                color: var(--dark);
                min-height: 42px;
                padding: rem(7) rem(10) !important;
                // min-width: 300px;
                margin-bottom: 10px;
                border-radius: 0;
                background-color: var(--alto);
                @include media(lg) {
                    padding: 0.925rem 2.125rem;
                }
                &.active {
                    background-color: var(--aqua-blue);
                    font-weight: bold;
                }
            }
        }
    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    a:hover {
        background-color: var(--aqua-blue);
    }
    .details-tabs-content {
        padding: rem(20);
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    }
}
