.data-reports {
    p {
        font-size: rem(14);
        font-weight: 700;
    }
    @include dropdown;
    .dropdown-menu {
        a.dropdown-item {
            justify-content: flex-start;
        }
    }
}