.custom-tooltip-body {
    opacity: 1 !important;
    max-width: rem(250);
    .arrow {
        &:before {
            border-top-color: #BCB8B2;
            // display: none;
        }
    }
    border: 1.5px solid #BCB8B2;
    box-shadow: 0px 1px 4px rgba(40, 49, 69, 0.3);
    background-color: var(--grey-light-3);
    color: var(--dark);
    font-weight: 400;
    font-size: rem(14);
    line-height: 20px;
    padding: rem(12) rem(10);
    text-align: left;
    border-radius: rem(8);
    z-index: 1151 !important;
}
