body {
    @mixin svgIcon($color) {
        svg {
            // path {
            //     fill: $color;
            // }
            rect {
                fill: $color
            }
        }
    }
    div.guard-assigned {
        @include svgIcon(#78BC61);
    }
    div.guard-unassigned {
        @include svgIcon(#CC0001);
    }
    div.guard-test-failed {
        &:hover {
            cursor: pointer;
        }
        @include svgIcon(#4EC3E0);
    }
    div.check-unassign {
       svg {
           path {
               stroke: #BCB8B2;
           }
       }
    }
    div.check-tentant {
        svg {
            path {
                stroke: #b75aeb;
            }
        }
    }
    div.check-onTime {
        svg {
            path {
                stroke: #78BC61;
            }
        }
    }
    div.check-buffer {
        svg {
            path {
                stroke: #FFB20F;
            }
        }
    }
    div.check-late {
        svg {
            path {
                stroke: #CC0001;
            }
        }
    }
}
