div.modal {
    &.modal--large {
        .modal-dialog {
            @include media(sm) {
                max-width: rem(1170);
            }
        }
    }
    &.available-guards-modal, &.view-job-location {
        .modal-dialog {
            @include media(sm) {
                max-width: rem(900);
            }
        }
    }
    &.activeJobsOnModal {
        .modal-dialog {
            @include media(sm) {
                max-width: rem(1170);
            }
            .modal-body {
                padding: 1rem 0.5rem;
                background-color: var(--grey-light-3);
                @include media(xl) {
                    padding: 1rem;
                }
                .filters-form-group {
                    background-color: #fff;
                }
            }
        }
    }
    &.edit-user-info {
        .modal-dialog {
            @include media(sm) {
                max-width: rem(830);
            }
        }
    }
    &.edit-schedule-modal {
        .modal-dialog {
            @include media(sm) {
                max-width: rem(950);
            }
            .schedule-form {
                margin-bottom: 0;
                margin-top: rem(30);
                &:after {
                    display: none;
                }
            }
        }
    }
    &.edit-shedule-confirmation-modal {
      .modal-dialog {
        max-width: rem(550);
      }
    }
    &.custom-message {
        .modal-dialog {
            @include media(sm) {
                max-width: rem(850);
            }
        }
    }
    &.modal-notifications-editor {
        .modal-dialog {
            max-width: rem(850);
        }
    }
    &.report-details-modal {
        .modal-dialog {
            max-width: rem(925);
        }
    }
    &.view-job-location {
       .modal-content {
            .modal-body {
                padding: 0;
                #job-location-map {
                    height: rem(500);
                }
            }
            .modal-footer {
                margin-left: auto;
                padding: rem(15) rem(10) rem(15) rem(10);
            }
       }
    }
    &-dialog {
        @include media(sm) {
            max-width: rem(520);
        }
        .modal-content {
            border: 0;
            border-radius: 8px;
            .modal-header {
                @include media(md) {
                    padding: rem(50) rem(50) 0 rem(50);
                }
            }
            .modal-body {
                padding: rem(15) rem(15);
                @include media(md) {
                    padding: rem(30) rem(50);
                }
                p,ul,li,a,b,strong,em,i {
                    font-size: rem(14);
                    color: #283044
                }
                b {
                    color: #292929;
                }
                h4 {
                    small {
                        font-size: rem(14);
                    }
                }
                span {
                    a {
                        font-size: inherit;
                        color: #283044;
                        pointer-events: none;
                    }
                }
                a {
                    font-size: inherit;
                }
                .custom-react-select {
                    &__value-container--is-multi {
                        padding-right: 0;
                        .css-b8ldur-Input {
                            margin: 0;
                            padding: 0;
                        }
                    }
                    &__multi-value__label {
                        font-size: 70%;
                    }
                }
            }
            .modal-footer {
                padding: rem(0) rem(15) rem(15) rem(15);
                @include media(md) {
                padding: rem(0) rem(50) rem(50) rem(50);
                }
                button {
                    &.btn-submit {
                        padding: 0 rem(40);
                    }
                    &.btn-cancel {
                      padding: 0 rem(40);
                  }
                }
            }
        }
    }
    svg.warning {
        path {
            fill: var(--warning);
        }
    }
}
.footer-modal-button{
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    @include media(sm) {
        width: auto;
    }
}

.add-new-item-modal {
    button.clear-btn {
        top:0;
        right: rem(15);
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &:active {
            outline: none;
            box-shadow: none;
        }
    }
}

.upload-file {
    form {
        input[type="file"] {
            font-size: rem(15);
            height: rem(44);
            border: 1px solid #BCB8B2;
            border-radius: 6px;
            color: #000;
            font-weight: bold;

            &:focus {
                outline: none;
                box-shadow: none;
                border-color: #BCB8B2;
                color: #000;
            }

            &::placeholder {
                color: #706E6A;
                font-weight: normal;
            }
        }
        .custom-file-label {
            &:after {
                font-size: rem(15);
                font-weight: 700;
                background-color: var(--aqua-blue);
                color: var(--dark);
                height: 100%;
            }
        }
    }
}
